<template>
  <div class="container-fluid container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item v-if="isEnrolledCounseling">
          <router-link
            :to="{
              name: 'counselingClassesDetail',
              params: { id: $route.params.id }
            }"
          >
            <IsOnlineIcon :isOnline="sessionClass.course_session.is_online" />
            {{ sessionClass.title }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-else>
          <router-link :to="{ name: 'counseling' }">
            Counseling
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div class="title">
      <h1>
        {{ pageTitle }}
        <router-link
          v-if="isEnrolledCounseling"
          :to="{
            name: 'addCounselingSchool',
            params: { counselingId: $route.params.id }
          }"
        >
          <i class="fas fa-plus" />
        </router-link>
        <router-link v-else :to="{ name: 'SchoolsList', query: { rank: 12 } }">
          <i class="fas fa-plus" />
        </router-link>
      </h1>
    </div>
    <hr class="separate-line" />
    <CollectCollegeTable
      :counselingId="Number($route.params.id)"
      :username="$route.params.username"
      :collectedCounselingSchools="collectedCounselingSchools"
      @fetchCollectedCounseling="fetchCollectedCounselingSchools"
      @removeSchoolFromFavorite="removeSchoolFromFavorite"
    />
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import IsOnlineIcon from "@/components/icon/IsOnline";
import CollectCollegeTable from "@/components/school/CollectCollegeTable";
import privateApi from "@/apis/private";
import collectSchoolApi from "@/apis/collectSchool";
import profileApi from "@/apis/profile";

export default {
  metaInfo() {
    return {
      title: "Colleges - Ivy-Way Academy"
    };
  },
  components: {
    Breadcrumb,
    IsOnlineIcon,
    CollectCollegeTable
  },
  data() {
    return {
      thirdServiceType: "common_app",
      counselingStudent: { first_name: "" },
      collectedCounselingSchools: [],
      sessionClass: {
        course_session: {}
      }
    };
  },
  computed: {
    pageTitle() {
      if (this.counselingStudent.first_name) {
        return `${this.counselingStudent.first_name || ""}'s Colleges`;
      } else {
        return "";
      }
    },
    counselingStudentId() {
      return this.counselingStudent.id;
    },
    isEnrolledCounseling() {
      return this.$route.name === "counselingSchool";
    }
  },
  async created() {
    if (this.isEnrolledCounseling) {
      await this.fetchCustomLesson();
    } else {
      await this.fetchUserProfile();
    }
    this.fetchCollectedCounselingSchools();
  },
  methods: {
    async fetchCustomLesson() {
      const customLesson = await privateApi.getCustomLesson({
        class_id: this.$route.params.id
      });
      this.sessionClass = {
        ...customLesson.session_class
      };
      this.counselingStudent =
        customLesson.student_class_tickets[0].student;
    },
    async fetchUserProfile() {
      const { basic_info, student: { counseling_schools_type } } = await profileApi.getUserByName(
        this.$route.params.username
      );
      this.counselingStudent = { ...basic_info, counseling_schools_type };
    },
    async fetchCollectedCounselingSchools() {
      const {
        counseling_schools
      } = await collectSchoolApi.fetchCollectedCounselingSchools({
        counselingId: 0,
        userId: this.counselingStudentId,
        counselingSchoolsType: this.counselingStudent.counseling_schools_type
      });
      this.collectedCounselingSchools = counseling_schools;
    },
    async removeSchoolFromFavorite(schoolId) {
      await this.$store.dispatch("collectSchool/removeRelatedSchool", schoolId);
      this.fetchCollectedCounselingSchools();
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  button {
    margin: 0px 8px;
  }
}
.container-fluid {
  padding: 20px;
  max-width: 95vw;
}
</style>
